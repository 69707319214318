// Import Link from react-scroll
import { Link } from "react-scroll";
import { motion } from 'framer-motion';
import backgroundVideoWebM from '../assets/Background-Video.webm';



const HeroSection = () => {
  return (
    <section className="relative flex flex-col items-center justify-center min-h-screen bg-transparent">
      {/* Background Video */}
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute inset-0 w-full h-full object-cover z-0 opacity-70" // Absolute positioning, lower z-index, slight opacity for better contrast
      >
        <source src={backgroundVideoWebM} type="video/webm" />
        {/* Fallback image for older browsers */}
        <img src="/fallback-image.jpg" alt="Background" className="w-full h-full object-cover" />
        Your browser does not support the video tag.
      </video>
      

      {/* Foreground Content */}
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.9 }}
        className="text-center relative z-10" // Higher z-index to ensure text is above video
      >
        <h1 className="text-5xl font-bold text-white gradient-text">Welcome to Vlocodeu</h1>
        <p className="text-lg mt-4 text-white">
          We bring innovative solutions to web, software, and mobile development.
        </p>
      </motion.div>

      {/* Button to Scroll to About Us */}
      <Link
        activeClass="active"
        to="contact" // The ID of the About Us section
        spy={true}
        smooth={true}
        offset={-70} // Optional, adjust the scroll position offset
        duration={500} // Scroll duration in ms
      >
        <motion.button
          className="relative z-10 mt-8 px-6 py-3 text-white rounded-lg shadow-lg bg-gradient-to-r from-teal-400 to-blue-500 hover:from-pink-500 hover:to-orange-500"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          Get Started
        </motion.button>
      </Link>
    </section>
  );
};

export default HeroSection;
