import React, { useState } from "react";
import { Link } from "react-scroll"; // For smooth scroll navigation
import "../styles.css";
import logo from "../assets/logo.png";

const Header = ({ activeSection, setActiveSection }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleSetActive = (section) => {
    setActiveSection(section);
    setMobileMenuOpen(false); // Close the mobile menu on link click
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header
      className="mt-4 mb-10 backdrop-blur-md bg-white/10 rounded-full mx-4 py-3 sm:mx-8 sm:py-3 fixed top-0 left-0 right-0 z-50"
      style={{
        backdropFilter: "blur(10px)",
        WebkitBackdropFilter: "blur(10px)",
        height: "60px",
      }}
    >
      <div className="container mx-auto flex justify-between items-center px-4">
        {/* Logo */}
        <div className="text-2xl font-bold text-white flex items-center">
          <img
            src={logo}
            alt="Vlocodeu Logo"
            className="h-8 pb-1 sm:h-10 " // Updated height for better mobile fit
          />
        </div>

        {/* Hamburger Icon for Mobile */}
        <div className="md:hidden">
          <button onClick={toggleMobileMenu} className="focus:outline-none">
            <svg
              className="w-6 h-6 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isMobileMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>

        {/* Navigation */}
        <nav className="hidden md:flex flex-grow justify-center space-x-4 sm:space-x-6">
          <ul className="flex justify-center space-x-4 sm:space-x-6">
            <li>
              <Link
                to="hero"
                smooth={true}
                duration={700}
                onClick={() => handleSetActive("hero")}
                className={`py-2 px-4 rounded-full transition cursor-pointer ${
                  activeSection === "hero" ? "neon-text" : "default-text"
                }`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="about"
                smooth={true}
                duration={700}
                onClick={() => handleSetActive("about")}
                className={`py-2 px-4 rounded-full transition cursor-pointer ${
                  activeSection === "about" ? "neon-text" : "default-text"
                }`}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="services"
                smooth={true}
                duration={700}
                onClick={() => handleSetActive("services")}
                className={`py-2 px-4 rounded-full transition cursor-pointer ${
                  activeSection === "services" ? "neon-text" : "default-text"
                }`}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="portfolio"
                smooth={true}
                duration={700}
                onClick={() => handleSetActive("portfolio")}
                className={`py-2 px-4 rounded-full transition cursor-pointer ${
                  activeSection === "portfolio" ? "neon-text" : "default-text"
                }`}
              >
                Portfolio
              </Link>
            </li>
            <li>
              <Link
                to="testimonials"
                smooth={true}
                duration={700}
                onClick={() => handleSetActive("testimonials")}
                className={`py-2 px-4 rounded-full transition cursor-pointer ${
                  activeSection === "testimonials" ? "neon-text" : "default-text"
                }`}
              >
                Testimonials
              </Link>
            </li>
            <li>
              <Link
                to="blog"
                smooth={true}
                duration={700}
                onClick={() => handleSetActive("blog")}
                className={`py-2 px-4 rounded-full transition cursor-pointer ${
                  activeSection === "blog" ? "neon-text" : "default-text"
                }`}
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="contact"
                smooth={true}
                duration={700}
                onClick={() => handleSetActive("contact")}
                className={`py-2 px-4 rounded-full transition cursor-pointer ${
                  activeSection === "contact" ? "neon-text" : "default-text"
                }`}
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <nav className="md:hidden absolute top-16 left-0 right-0 bg-white/80 rounded-lg shadow-lg">
            <ul className="flex flex-col items-center space-y-4 py-4">
              <li>
                <Link
                  to="hero"
                  smooth={true}
                  duration={700}
                  onClick={() => handleSetActive("hero")}
                  className={`py-2 px-4 rounded-full transition cursor-pointer ${
                    activeSection === "hero" ? "neon-text" : "default-text"
                  }`}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="about"
                  smooth={true}
                  duration={700}
                  onClick={() => handleSetActive("about")}
                  className={`py-2 px-4 rounded-full transition cursor-pointer ${
                    activeSection === "about" ? "neon-text" : "default-text"
                  }`}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="services"
                  smooth={true}
                  duration={700}
                  onClick={() => handleSetActive("services")}
                  className={`py-2 px-4 rounded-full transition cursor-pointer ${
                    activeSection === "services" ? "neon-text" : "default-text"
                  }`}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="portfolio"
                  smooth={true}
                  duration={700}
                  onClick={() => handleSetActive("portfolio")}
                  className={`py-2 px-4 rounded-full transition cursor-pointer ${
                    activeSection === "portfolio" ? "neon-text" : "default-text"
                  }`}
                >
                  Portfolio
                </Link>
              </li>
              <li>
                <Link
                  to="testimonials"
                  smooth={true}
                  duration={700}
                  onClick={() => handleSetActive("testimonials")}
                  className={`py-2 px-4 rounded-full transition cursor-pointer ${
                    activeSection === "testimonials" ? "neon-text" : "default-text"
                  }`}
                >
                  Testimonials
                </Link>
              </li>
              <li>
                <Link
                  to="blog"
                  smooth={true}
                  duration={700}
                  onClick={() => handleSetActive("blog")}
                  className={`py-2 px-4 rounded-full transition cursor-pointer ${
                    activeSection === "blog" ? "neon-text" : "default-text"
                  }`}
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  to="contact"
                  smooth={true}
                  duration={700}
                  onClick={() => handleSetActive("contact")}
                  className={`py-2 px-4 rounded-full transition cursor-pointer ${
                    activeSection === "contact" ? "neon-text" : "default-text"
                  }`}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
